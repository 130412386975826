import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import GlossaryPage from '../components/glossary-page';

const GlossaryJewelleryTemplate = ({ data, pageContext }) => (
  <GlossaryPage data={data} pageContext={pageContext} />
);

GlossaryJewelleryTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GlossaryJewelleryTemplate;

export const query = graphql`
query glossaryJewelleryQuery($url: String, $lang: String) {
    datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        ...content
        metaCanonical
        metaRobots
    }
    
    allDatoCmsLexicon(filter: {category: {eq: "Schmuck"} locale: {eq: $lang}} ,sort: {order: ASC, fields: term}) {
        nodes {
            id
            term
            category
            description {
                value
                blocks {
                    ... on DatoCmsImage {
                        __typename
                        id
                        originalId
                        image {
                            gatsbyImageData(
                                forceBlurhash: true,
                                imgixParams: {
                                    w:"640"
                                    auto: "compress"
                                    q: 70
                                    dpr: 1.5
                                }
                            )
                            alt
                            title
                        }
                    }
                    ... on DatoCmsTable {
                        __typename
                        id
                        originalId
                        tableNode {
                            internal {
                                content
                            }
                        }
                    }
                }
            }
        }
    }
    
    filterQueryNumber: allDatoCmsLexicon(
        filter: {category: {eq: "Schmuck"}, locale: {eq: "de"}, term: {regex: "/^[0-9]/"}}
        sort: {order: ASC, fields: term}
    ) {
        nodes {
            id
            term
            category
            description {
                value
                blocks {
                    ... on DatoCmsImage {
                        __typename
                        id
                        originalId
                        image {
                            gatsbyImageData(
                                forceBlurhash: true,
                                imgixParams: {
                                    w:"640"
                                    auto: "compress"
                                    q: 70
                                    dpr: 1.5
                                }
                            )
                            alt
                            title
                        }
                    }
                    ... on DatoCmsTable {
                        __typename
                        id
                        originalId
                        tableNode {
                            internal {
                                content
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
